import headerList from "@/components/common/header-list.vue";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import { _ } from "vue-underscore";
export default {
  data: () => ({
    companytList: [],
    searchText: "",
    showLoader: false,
    selectedFile: [],
    selected: "",
    selectedId: 0,
    selectedRows: [],
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
  }),
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },
  computed: {
    selectAllList() {
      if (this.companytList.length > 0) {
        return (
          _.where(this.companytList, { isSelected: true }).length ===
          this.companytList.length
        );
      }
      return false;
    },
  },
  methods: {
    checkUncheckedList(event) {
      this.companytList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        this.companytList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      this.modal7;
      _vm.axios
        .post("/company-deletes", { ids: ids })
        .then(function (response) {
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {});
    },

    // getNextPage(page) {
    //   this.$refs.paginate.selected = page;
    //   this.paginate.current_page = page;
    //   this.getAll();
    // },
    search() {
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      this.axios
        .get("/company" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let data = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          for (let index = 0; index < data.length; index++) {
            data[index]["isSelected"] = false;
          }
          _vm.companytList = data;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {});
    },
    handleCheck(e) {
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          this.selectedRows = this.companytList.map(
            (item) => "checkbox" + item.id
          );
          this.companytList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          this.selectedRows = [];
          this.companytList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          this.selectedRows.push(e.target.id);
        } else {
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
  },
};
